import "@tabler/core/dist/css/tabler.min.css";
import "./App.css";
import "./flatpickr.css";

import { NewRevisionForm } from "./NewRevisionForm";
import React, { useState } from "react";
import { RevisionList } from "./RevisionList";
import { RevisionEdit } from "./RevisionEdit";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Container } from "react-bootstrap";
import { ArticleEdit } from "./ArticleEdit";

export const apiBase = process.env.REACT_APP_BACKEND_API_URL;

function App() {
  return (
    <Container>
      <BrowserRouter>
        <Routes>
          <Route path={"/"} element={<Homepage />} />
          <Route
            path={"/revisions/:revisionId/articles/:articleId"}
            element={<ArticleEdit />}
          />
          <Route path={"/revisions/:revisionId"} element={<RevisionEdit />} />
        </Routes>
      </BrowserRouter>
    </Container>
  );
}

let Homepage = () => {
  const [isFetching, setIsFetching] = useState(false);

  return (
    <Container>
      <h1 className={"my-4"}>Mawek</h1>
      <NewRevisionForm
        onFetchStart={() => setIsFetching(true)}
        onFetchEnd={(_) => {
          setIsFetching(false);
        }}
      />
      {!isFetching && <RevisionList />}
    </Container>
  );
};

export default App;

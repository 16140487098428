import { ErrorMessage, useField } from "formik";
import Flatpickr from "react-flatpickr";
import React from "react";
import Form from "react-bootstrap/Form";

export const FlatpickrFormGroup = ({ name, label, ...props }) => {
  const [field, meta, helpers] = useField({
    name: name,
    ...props,
  });

  return (
    <Form.Group>
      <Form.Label column={false} htmlFor={name}>
        {label}
      </Form.Label>

      <Flatpickr
        name={name}
        className="form-control"
        value={field.value}
        onChange={(date) => {
          helpers.setValue(date[0]);
          helpers.setTouched(true);
        }}
        {...props}
      />

      <ErrorMessage name={name} />
    </Form.Group>
  );
};

import Card from "react-bootstrap/Card";
import { useQuery } from "react-query";
import React from "react";
import { apiBase } from "./App";
import { Link, useParams } from "react-router-dom";
import { Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

export const RevisionEdit = () => {
  let { revisionId } = useParams();
  let navigate = useNavigate();

  const {
    isLoadingRevision,
    errorRevision,
    data: revision,
    refetchRevision,
  } = useQuery("revision_data", () =>
    fetch(`${apiBase}/revisions/${revisionId}`)
      .then((res) => res.json())
      .then((res) => res.revision)
  );

  const {
    isLoadingArticles,
    errorArticles,
    data: articles,
    refetchArticles,
  } = useQuery("article_content", () =>
    fetch(`${apiBase}/revision/${revisionId}/articles`)
      .then((res) => res.json())
      .then((res) => res.articles)
  );

  return isLoadingArticles || isLoadingRevision ? (
    <p>Loading</p>
  ) : errorArticles ? (
    <p className="text-danger">Erreur : {errorArticles}</p>
  ) : errorRevision ? (
    <p className="text-danger">Erreur : {errorRevision}</p>
  ) : articles && revision ? (
    <Container className={"pb-5"}>
      <Card className={"mt-4"}>
        <Card.Body className={"row align-items-center"}>
          <h1 className={"col m-0"}>{revision.name}</h1>
          <div className="col-auto">
            <Link className="btn btn-danger" to={"/"}>
              Quitter
            </Link>
          </div>
        </Card.Body>
      </Card>

      <Card className={"mt-4"}>
        <table className={"table table-vcenter card-table table-striped m-0 cursor-pointer"}>
          <tbody>
            {articles.map((article) => (
              <tr
                key={article.id}
                className={"text-body"}
                onClick={() =>
                  navigate(`/revisions/${revisionId}/articles/${article.id}`)
                }
              >
                <td className={"fit"}>
                  <span
                    className={`mb-1 badge bg-${
                      { CREATED: "green", DELETED: "red", MODIFIED: "blue" }[
                        article.status
                      ]
                    }`}
                  />
                </td>
                <td>{article.name}</td>
                <td className="text-right text-muted">{article.comment}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </Card>
    </Container>
  ) : null;
};

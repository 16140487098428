import React, { useEffect, useState } from "react";
import Card from "react-bootstrap/Card";
import { apiBase } from "./App";
import "dayjs/locale/fr";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { useParams, useNavigate } from "react-router-dom";
import { useQuery } from "react-query";

dayjs.extend(customParseFormat);
dayjs.locale("fr");

/* Text URL to show the reference of the modification */
// const textUrl = (textID) =>
//   `https://www.legifrance.gouv.fr/loda/id/${textID}/#${textID}`;

/* Text URL to show the content of the article */
const textUrl = ({ name }) => {
  const urlTemplate =
    "https://www.legifrance.gouv.fr/eli/loi/{year}/{month}/{day}/{id}/jo/texte";

  let lawId = name.replace("LOI n°", "").trim().split(" du ")[0];
  let date = name.split("du ")[1].split(" ").slice(0, 3).join(" ");
  let parsedDate = dayjs(date, "D MMMM YYYY");

  return urlTemplate
    .replace("{id}", lawId)
    .replace("{year}", parsedDate.format("YYYY"))
    .replace("{month}", (parsedDate.month() + 1).toString())
    .replace("{day}", parsedDate.format("D"));
};

const statusLabel = {
  CREATED: "ajouté",
  DELETED: "supprimé",
  MODIFIED: "modifié",
};

const statusColor = {
  CREATED: "green",
  DELETED: "red",
  MODIFIED: "blue",
};

export const ArticleEdit = () => {
  let { articleId, revisionId } = useParams();
  let [show, setShow] = useState("ALL"); // ALL, ADDITION, DELETION
  let [comment, setComment] = useState();
  let navigate = useNavigate();

  const {
    isLoading,
    error,
    data: article,
    refetch,
  } = useQuery("article_data_" + articleId, () =>
    fetch(`${apiBase}/articles/${articleId}`).then((res) => res.json())
  );

  const {
    isLoading: isLoadingAllArticlesId,
    error: allArticlesIdError,
    data: allArticlesId,
    refetch: refetchAllArticlesId,
  } = useQuery("all_articles_data", () =>
    fetch(`${apiBase}/revision/${revisionId}/articles`)
      .then((res) => res.json())
      .then((res) => res.articles.map((a) => a.id))
  );

  const goBackToList = () => {
    navigate(`/revisions/${revisionId}`);
  };

  const goToNextArticle = () => {
    let greaterIds = allArticlesId.filter((id) => id > articleId);
    greaterIds.sort();

    if (greaterIds.length > 0) {
      navigate(`/revisions/${revisionId}/articles/${greaterIds[0]}`);
    } else {
      goBackToList();
    }
  };

  const save = () =>
    fetch(`${apiBase}/articles/${article.id}`, {
      method: "POST",
      body: JSON.stringify({ id: article.id.toString(), comment: comment }),
    });

  useEffect(() => {
    if (article) {
      setComment(article.comment);
    }
  }, [article]);

  return isLoading ? (
    <p>Loading</p>
  ) : error ? (
    <p className="text-danger">Erreur : {error}</p>
  ) : article && article.content ? (
    <>
      <Card className={"mt-3"}>
        <Card.Header>
          <Card.Title>
            {article.name}{" "}
            <span className={`badge bg-${statusColor[article.status]}-lt`}>
              {statusLabel[article.status]}
            </span>
          </Card.Title>
          {article.status === "MODIFIED" && (
            <div className="btn-list card-options">
              <button
                className={`btn ${
                  show === "ADDITION" ? "btn-success" : "btn-ghost-success"
                } `}
                onClick={() => setShow("ADDITION")}
              >
                Ajouts
              </button>
              <button
                className={`btn ${
                  show === "ALL" ? "btn-light" : "btn-ghost-secondary"
                }`}
                onClick={() => setShow("ALL")}
              >
                Ajouts et suppressions
              </button>
              <button
                className={`btn ${
                  show === "DELETION" ? "btn-danger" : "btn-ghost-danger"
                }`}
                onClick={() => setShow("DELETION")}
              >
                Suppressions
              </button>
            </div>
          )}
        </Card.Header>

        <Card.Body>
          <p>
            {article.status === "CREATED"
              ? renderHTML(article.content, "ins")
              : article.status === "DELETED"
              ? renderHTML(article.content, "del")
              : article.content.map(([type, content], index) => (
                  <span key={index}>
                    {type === "+"
                      ? show !== "DELETION"
                        ? renderHTML(content, "ins")
                        : null
                      : type === "-"
                      ? show !== "ADDITION"
                        ? renderHTML(content, "del")
                        : null
                      : renderHTML(content, "span")}{" "}
                  </span>
                ))}
          </p>
        </Card.Body>
        <Card.Footer>
          article {statusLabel[article.status]} par{" "}
          {intersperse(
            article.modifiers.map((modifier) => (
              <a key={modifier.tagId} href={textUrl(modifier)} target="_blank">
                {modifier.name}
              </a>
            )),
            ", "
          )}
        </Card.Footer>
      </Card>

      <Card className={"mt-3"}>
        <textarea
          id=""
          cols="30"
          rows="10"
          className={"form-control"}
          value={comment}
          onChange={(e) => setComment(e.target.value)}
        />
      </Card>

      {/*Choose which elements are rendered*/}
      <div className="row mt-2">
        <div className="col">
          <span
            className="btn btn-link"
            onClick={() => save().then(goBackToList)}
          >
            Changer d'article
          </span>
        </div>
        <div className="col-auto">
          <button
            className="btn btn-success text-left"
            onClick={() => save().then(goToNextArticle)}
          >
            Article suivant
          </button>
        </div>
      </div>
    </>
  ) : null;
};

const renderHTML = (rawHTML, type) =>
  React.createElement(type, {
    dangerouslySetInnerHTML: { __html: rawHTML.replace("\n", "<br/>") },
  });

function intersperse(arr, sep) {
  if (arr.length === 0) {
    return [];
  }

  return arr.slice(1).reduce(
    function (xs, x, i) {
      return xs.concat([sep, x]);
    },
    [arr[0]]
  );
}

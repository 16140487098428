import { ErrorMessage, useField } from "formik";
import Form from "react-bootstrap/Form";
import FormControl from "react-bootstrap/FormControl";

export const TextFormGroup = ({ name, label, ...props }) => {
  const [field, meta] = useField({
    name: name,
    ...props,
  });

  return (
    <Form.Group>
      <Form.Label column={false} htmlFor={name}>
        {label}
      </Form.Label>

      <Form.Control
        type="text"
        name={name}
        {...field}
        {...props}
        isInvalid={meta.touched && meta.error}
      />

      {meta.touched && meta.error && (
        <FormControl.Feedback type="invalid">{meta.error}</FormControl.Feedback>
      )}
    </Form.Group>
  );
};

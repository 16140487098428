import Card from "react-bootstrap/Card";
import { useQuery } from "react-query";
import { apiBase } from "./App";
import dayjs from "dayjs";
import {Link} from "react-router-dom";

export const RevisionList = () => {
  const { isLoading, error, data, refetch } = useQuery("list_revisions", () =>
    fetch(apiBase + "/revisions", {
      crossDomain: true,
      headers: { "Content-Type": "application/json" },
    })
      .then((res) => res.json())
      .then((res) => {
        let revisions = res.revisions;
        revisions.reverse();
        return revisions;
      })
  );

  const deleteRevision = async (revision) => {
    if (
      window.prompt(`Pour supprimer la revisions entrez "${revision.name}"`) ===
      revision.name
    ) {
      await fetch(`${apiBase}/revisions/${revision.id}`, { method: "DELETE" });
      await refetch();
    }
  };

  if (isLoading) return "Loading...";
  if (error) return "An error has occurred: " + error.message;

  return (
    <div className={"row mt-3"}>
      {data.map((revision) => (
        <div key={revision.id} className={"col-md-3"}>
          <Card className={"mb-3"}>
            <Card.Body>
              <h2 className={"card-title"}>{revision.name}</h2>
              <p className={"card-subtitle"}>
                Du {dayjs(revision.from).format("DD/MM/YYYY")} au{" "}
                {dayjs(revision.to).format("DD/MM/YYYY")}
              </p>
              <p className={"row"}>
                <Link
                  to={"/revisions/" + revision.id}
                  className="btn btn-ghost-secondary d-block col-md-6"
                >
                  <span className="pr-1">Modifier</span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-pen"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M13.498.795l.149-.149a1.207 1.207 0 1 1 1.707 1.708l-.149.148a1.5 1.5 0 0 1-.059 2.059L4.854 14.854a.5.5 0 0 1-.233.131l-4 1a.5.5 0 0 1-.606-.606l1-4a.5.5 0 0 1 .131-.232l9.642-9.642a.5.5 0 0 0-.642.056L6.854 4.854a.5.5 0 1 1-.708-.708L9.44.854A1.5 1.5 0 0 1 11.5.796a1.5 1.5 0 0 1 1.998-.001zm-.644.766a.5.5 0 0 0-.707 0L1.95 11.756l-.764 3.057 3.057-.764L14.44 3.854a.5.5 0 0 0 0-.708l-1.585-1.585z"
                    />
                  </svg>
                </Link>

                <a
                  href={`${apiBase}/revisions/${revision.id}/download/excel`}
                  className="d-block col-md-2 btn btn-ghost-success"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-file-earmark-excel-fill"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M2 2a2 2 0 0 1 2-2h5.293A1 1 0 0 1 10 .293L13.707 4a1 1 0 0 1 .293.707V14a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2zm7.5 1.5v-2l3 3h-2a1 1 0 0 1-1-1zM5.884 6.68a.5.5 0 1 0-.768.64L7.349 10l-2.233 2.68a.5.5 0 0 0 .768.64L8 10.781l2.116 2.54a.5.5 0 0 0 .768-.641L8.651 10l2.233-2.68a.5.5 0 0 0-.768-.64L8 9.219l-2.116-2.54z"
                    />
                  </svg>
                </a>
                <a
                  href={`${apiBase}/revisions/${revision.id}/download/word`}
                  className="d-block col-md-2 btn btn-ghost-primary"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-file-earmark-word-fill"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M2 2a2 2 0 0 1 2-2h5.293A1 1 0 0 1 10 .293L13.707 4a1 1 0 0 1 .293.707V14a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2zm7.5 1.5v-2l3 3h-2a1 1 0 0 1-1-1zM5.485 6.879a.5.5 0 1 0-.97.242l1.5 6a.5.5 0 0 0 .967.01L8 9.402l1.018 3.73a.5.5 0 0 0 .967-.01l1.5-6a.5.5 0 0 0-.97-.242l-1.036 4.144-.997-3.655a.5.5 0 0 0-.964 0l-.997 3.655L5.485 6.88z"
                    />
                  </svg>
                </a>

                <button
                  onClick={() => deleteRevision(revision)}
                  className="d-block col-md-2 btn btn-ghost-danger "
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-trash"
                    viewBox="0 0 16 16"
                  >
                    <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                    <path
                      fill-rule="evenodd"
                      d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4L4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
                    />
                  </svg>
                </button>
              </p>
            </Card.Body>
            <Card.Footer>{revision.code.name}</Card.Footer>
          </Card>
        </div>
      ))}
    </div>
  );
};

import Card from "react-bootstrap/Card";
import { Form, Formik, Field } from "formik";

import dayjs from "dayjs";
import * as Yup from "yup";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { TextFormGroup } from "./utils/TextFormGroup";
import React, { useEffect, useState } from "react";
import { FlatpickrFormGroup } from "./utils/FlatpickrFormGroup";
import { useQuery } from "react-query";
import { apiBase } from "./App";

export const NewRevisionForm = ({ onFetchStart, onFetchEnd }) => {
  const [error, setError] = useState("");
  const { isLoading, error: codesError, data: codes } = useQuery(
    "list_codes",
    () =>
      fetch(apiBase + "/codes")
        .then((res) => res.json())
        .then((res) => res.codes)
  );

  useEffect(() => {
    if (codesError) {
      setError(codesError);
    }
  }, [codesError]);

  const [isUploading, setIsUploading] = useState(false);

  const onSubmit = (values, { setSubmitting }) => {
    setIsUploading(true);
    onFetchStart();
    fetch(apiBase + "/revisions", {
      method: "POST",
      body: JSON.stringify(values),
    }).then(async (res) => {
      if (res.status === 201) {
        let revision = JSON.parse(await res.text());
        onFetchEnd(revision);
      } else if (res.status >= 400) {
        setError(await res.text());
      }
      setIsUploading(false);
      setSubmitting(false);
    });
  };

  return error ? (
    <p className={"text-danger"}>{error}</p>
  ) : (
    !isLoading &&
      codes &&
      (isUploading ? (
        <div className={"text-center"}>
          <div
            className="spinner-border spinner-border-lg text-blue"
            role="status"
          />
          <p className="lead">Envoi en cours...</p>
        </div>
      ) : (
        <Card>
          <Card.Body>
            <h2>Nouvelle révision</h2>

            <Formik
              initialValues={{
                name: "",
                from: dayjs().add(-3, "month").format(),
                to: dayjs().format(),
                code: codes[0]["tagId"],
              }}
              validationSchema={Yup.object().shape({
                name: Yup.string().required("Veuillez entrer un nom."),
                from: Yup.date().required(
                  "Veuillez entrer une date de départ."
                ),
                to: Yup.date()
                  .min(
                    Yup.ref("from"),
                    "La date de fin doit être après la date d'arrivée."
                  )
                  .required("Veuillez entrer une date de fin."),
              })}
              onSubmit={onSubmit}
            >
              {({ isSubmitting, values }) => (
                <Form>
                  <TextFormGroup label="Nom de la révision" name="name" />

                  <Row className={"my-3"}>
                    <Col>
                      <FlatpickrFormGroup name="from" label="Date de départ" />
                    </Col>
                    <Col>
                      <FlatpickrFormGroup
                        name="to"
                        label="Date d'arrivée"
                        options={{
                          minDate: values.from,
                        }}
                      />
                    </Col>
                  </Row>

                  <Field as={"select"} className={"form-control"} name="code">
                    {codes.map((code) => (
                      <option key={code.tagId} value={code.tagId}>
                        {code.name}
                      </option>
                    ))}
                  </Field>

                  <button
                    type="submit"
                    className={"btn btn-primary m-auto d-block mt-4"}
                    disabled={isSubmitting}
                  >
                    Créer
                  </button>
                </Form>
              )}
            </Formik>
          </Card.Body>
        </Card>
      ))
  );
};
